import { signIn, useSession } from "next-auth/react";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export const AuthGuard = ({ children }: Props) => {
  const { status } = useSession({
    required: true,
    onUnauthenticated() {
      signIn();
    },
  });

  if (status === "loading") {
    return null;
  }

  return <>{children}</>;
};
